import { IconButton } from '../../../ui/buttons/icon-button';
import { NotificationsIcon } from '../../../icons/material/Notifications';
import { Button } from '../../../ui/buttons/button';
import { useReleasedBalance, useSupportedBalance } from './requests/support-balance-use';
import { ProgressCircle } from '../../../ui/progress/progress-circle';
// import {NotificationList} from '../notification-list';
import { DialogTrigger } from '../../../ui/overlays/dialog/dialog-trigger';
import { Dialog } from '../../../ui/overlays/dialog/dialog';
import { DialogHeader } from '../../../ui/overlays/dialog/dialog-header';
import { DialogBody } from '../../../ui/overlays/dialog/dialog-body';
import { Trans } from '../../../i18n/trans';
import { useAuth } from '../../../auth/use-auth';
import { Badge } from '../../../ui/badge/badge';
import { DoneAllIcon } from '../../../icons/material/DoneAll';
import { Chip } from '@common/ui/forms/input-field/chip-field/chip';
import { useArtist } from '@app/web-player/artists/requests/use-artist';
import { usePrimaryArtistForCurrentUser } from '@app/web-player/backstage/use-primary-artist-for-current-user';
import { Fragment, useState } from 'react';
import { EditIcon } from '@common/icons/material/Edit';
import { BalanceSupportWithdrawsDialog } from './balance-supportwithdraws-dialog';
import { ItemTransactionsW, useSupportedBalanceWithdraw } from './requests/support-balance-withdraw-use';
// import { NotificationEmptyStateMessage } from '@common/notifications/empty-state/notification-empty-state-message';
// import {useMarkNotificationsAsRead} from '../requests/use-mark-notifications-as-read';
import { NotificationEmptyStateMessage } from './empty-state/notification-empty-state-message';
import { NotificationList } from '@common/notifications/notification-list';
import { List, ListItem } from '@common/ui/list/list';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { Link } from 'react-router-dom';
import pointsIcon from './points_coin_icon.png';
import { usePointsBalance } from './requests/points-balance-use';

interface SupportBalanceDialogTriggerProps {
  className?: string;
  isArtist: boolean;
  artistVerified: boolean;
  isClose: boolean;
}
interface Payload {
  artistId: number;
}
export function SupportBalanceDialogTrigger({
  className,
  isArtist,
  artistVerified,
  isClose
}: SupportBalanceDialogTriggerProps) {
  const { user } = useAuth();
  const primaryArtist = usePrimaryArtistForCurrentUser();
  const artistId = primaryArtist?.id as number || 0;
  // const [isWithdrawDialogOpen, setWithdrawDialogOpen] = useState(false);

  // const openWithdrawDialog = () => {
  //   // Close other dialogs here
  //   // ...

  //   // Open the Withdraw dialog
  //   setWithdrawDialogOpen(true);

  //   console.log('isWithdrawDialogOpen', isWithdrawDialogOpen);
  // };

  // const closeWithdrawDialog = () => {
  //   setWithdrawDialogOpen(false);
  // };

  // const handleWithdrawClick = () => {
  //   // Close other dialogs here if needed
  //   // ...

  //   // Open the Withdraw dialog
  //   // Optionally, you can pass the formId to your dialog if needed
  //   close(); // Close any existing dialog
  //   // Additional logic to open the Withdraw dialog
  // };


  const myPayload: Payload = {
    artistId: artistId,
  };

  const myPayloadPoints = {
    userId: user?.id || 0,
  };

  const queryPoints = usePointsBalance(myPayloadPoints);

  let balance = 0;
  const query = useSupportedBalance(myPayload);
  if (isArtist && artistVerified) {
    balance = query.data?.pagination.data[0].balance || 0;
  }

  let releaseBalance = 0;
  if (artistId) {
    const releaseBalanceQuery = useReleasedBalance({ artistId })
    releaseBalance = releaseBalanceQuery.data?.balance || 0;
  }

  // const markAsRead = useMarkNotificationsAsRead();
  const hasUnread = !!user?.unread_notifications_count;
  const balancePoints = queryPoints.data?.pagination.data[0].points || 0;


  return (
    <DialogTrigger type="popover" isDismissable={true}>
      <Badge
        // badgeLabel={user?.unread_notifications_count}
        badgeIsVisible={false}
      >
        <IconButton size={null} className={className}

        >
          <Chip size="xs" color="primary" radius="rounded-full" className="w-max">
            {/* <span style={{fontWeight: 'bold'}}>$<span className='balanceGlobalIcon'><Trans message={`${balance}`}  /></span></span> */}
            <span style={{ fontWeight: 'bold' }}>Earnings</span>
          </Chip>

        </IconButton>
      </Badge>

      <Dialog size='lg'>
        <DialogHeader
          showDivider
        // rightAdornment={
        //   hasUnread && (
        //     <Button
        //       variant="text"
        //       color="primary"
        //       size="xs"
        //       startIcon={<DoneAllIcon />}
        //       onClick={handleMarkAsRead}
        //       disabled={markAsRead.isLoading}
        //     >
        //       <Trans message="Mark all as read" />
        //     </Button>
        //   )
        // }
        >
          <Trans message="Earnings" />
        </DialogHeader>
        <DialogBody padding="p-0">
          <DialogContent balance={balance} releaseBalance={releaseBalance} artistId={artistId} balancePoints={balancePoints} isArtist={isArtist} artistVerified={artistVerified} />

        </DialogBody>
      </Dialog>
    </DialogTrigger>
  );
}

function DialogContent({ balance, releaseBalance, artistId, balancePoints, isArtist, artistVerified }: { balance: number; releaseBalance: number, artistId: number, balancePoints: number, isArtist: boolean, artistVerified: boolean }) {

  return (
    <div>
      {isArtist && artistVerified &&
        <div>
          <div style={{ display: 'flex', placeContent: 'space-between', padding: '10px 14px' }}>
            <h3><span style={{ fontWeight: 'bold' }}>Your balance</span> <span style={{ fontSize: '10px' }} className='text-muted'>(from supports earnings)</span></h3>
            <h3 style={{ fontSize: '18px', fontWeight: 'bold' }} className='text-primary'>${balance}</h3>
          </div>

          <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
            <hr style={{ width: 'calc(100% - 28px)' }} />
          </div>
        </div>
      }

      <div style={{ display: 'flex', placeContent: 'space-between', padding: '10px 14px' }}>
        <h3><span style={{ fontWeight: 'bold' }}>Your royalties</span> <span style={{ fontSize: '10px' }} className='text-muted'>(from distribution earnings)</span></h3>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold' }} className='text-primary'>€{releaseBalance.toFixed(2)}</h3>
      </div>
      <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
        <hr style={{ width: 'calc(100% - 28px)' }} />
      </div>
      <div style={{ display: 'flex', placeContent: 'space-between', padding: '10px 14px' }}>
        <h3><span style={{ fontWeight: 'bold' }}>Your points</span> <span style={{ fontSize: '10px' }} className='text-muted'>(from activities earnings)</span></h3>
        <h3 style={{ fontSize: '18px', fontWeight: 'bold', display: 'flex' }} className='text-primary'>
          <img
            loading="lazy"
            src={pointsIcon}
            style={{
              width: '20px',
              height: 'auto',
              marginRight: '1px'
            }}
          />
          {balancePoints}</h3>
      </div>

      <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
        <hr style={{ width: 'calc(100% - 28px)' }} />
      </div>

      {/* <h2 style={{textAlign: 'center', fontSize: '30px', marginTop: '16px', paddingBottom: '10px'}}>Your balance</h2>
      <div style={{textAlign: 'center', display: 'flex', placeContent: 'center'}}>
        <hr style={{width: '50%'}} />
      </div>
      <h2 style={{textAlign: 'center', fontSize: '30px', fontWeight: 'bold', margin: '16px 0', marginBottom: '50px'}} className='text-primary'>${balance}</h2> */}

      <div style={{ textAlign: 'center', marginBottom: '16px', marginTop: '16px' }}>
        <BalanceWithdrawArtistBtn artistId={artistId} isArtist={isArtist} artistVerified={artistVerified} />
        <BalanceWithdrawList artistId={artistId} />
      </div>
    </div>
  );
}

interface BalanceWithdrawArtistProps {
  artistId: number;
  isArtist?: boolean;
  artistVerified?: boolean;
}
function BalanceWithdrawArtistBtn({ artistId, isArtist, artistVerified }: BalanceWithdrawArtistProps) {
  const { close, formId } = useDialogContext();
  const variant = (!isArtist || !artistVerified) ? "flat" : "outline";
  return (
    // <Fragment>
    //   <DialogTrigger type="modal" isDismissable={false}>
    //     <Button size="md" 
    //     variant="flat"
    //     color="primary"
    //     >
    //       <Trans message='Withdraw'/>
    //     </Button>
    //     <BalanceSupportWithdrawsDialog artistId={artistId} />
    //   </DialogTrigger>
    // </Fragment>



    // <Fragment>
    //   <DialogTrigger type="modal" isDismissable={false}>
    <div>


      {isArtist && artistVerified &&
        <Link to="?withdraw=true" className='mr-8' onClick={() => {
          close();
        }}>
          <Button size="xs" variant="flat" color="primary">
            <Trans message='Withdraw balance' />
          </Button>
        </Link>
      }

      <Link to="?redeem_points=true" onClick={() => {
        close();
      }}>
        <Button size="xs" variant={variant} color="primary">
          <Trans message='Redeem points' />
        </Button>
      </Link>

      {/* <BalanceSupportWithdrawsDialog artistId={artistId} /> */}
    </div>
    //   </DialogTrigger>
    // </Fragment>
  );
}

function BalanceWithdrawList({ artistId }: BalanceWithdrawArtistProps) {
  const myPayload: Payload = {
    artistId: artistId,
  };
  const { data, isLoading } = useSupportedBalanceWithdraw(myPayload);
  if (isLoading) {
    return (
      <div style={{ marginTop: '16px' }}>
        <hr />
        <h3 style={{ fontWeight: 'bold', paddingTop: '10px', paddingBottom: '5px' }}>Transactions</h3>
        <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
          <hr style={{ width: '50px' }} />
        </div>

        <div className="flex items-center justify-center px-24 py-20 ">
          <ProgressCircle aria-label="Loading withdraw list..." isIndeterminate />
        </div>
      </div>
    );
  }
  const dataNew = Object.values(data?.pagination.data || {});
  // return (
  //   <div></div>
  // );
  if (!dataNew.length) {
    return (
      <div style={{ marginTop: '16px' }}>
        <hr />
        <h3 style={{ fontWeight: 'bold', paddingTop: '10px', paddingBottom: '5px' }}>Transactions</h3>
        <div style={{ textAlign: 'center', display: 'flex', placeContent: 'center' }}>
          <hr style={{ width: '50px' }} />
        </div>

        <div className="px-24 py-20">
          <NotificationEmptyStateMessage />
        </div>
      </div>
    );
  }

  return (
    <div style={{ marginTop: '16px' }}>
      <hr />
      <h3 style={{ fontWeight: 'bold', paddingTop: '10px', paddingBottom: '5px' }}>Transactions</h3>
      {/* <div style={{textAlign: 'center', display: 'flex', placeContent: 'center'}}>
        <hr style={{width: '50px'}} />
      </div> */}

      <div className='' style={{ overflowY: 'auto' }}>
        <table style={{ width: 'calc(100% - 28px)', margin: 'auto', borderCollapse: 'collapse', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>Item</th>
              <th style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>Details or Instructions</th>
              <th style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>Amount</th>
              <th style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>Status</th>
              <th style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {dataNew.map((transaction, index) => {
              const withdrawDate = new Date(transaction.created_at);
              const formattedDate = new Intl.DateTimeFormat('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }).format(withdrawDate);

              return (
                <tr>
                  {transaction.item_type === 'support_withdraws' && (
                    <><td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                      <h4 style={{}}><span style={{ fontWeight: 'bold', fontStyle: 'italic', textTransform: 'uppercase', opacity: '0.65' }}>Your balance</span></h4>
                    </td><td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                        <h4 style={{}}><span style={{ fontWeight: 'bold' }}>{transaction.payment_method}</span> - {transaction.payment_details}</h4>
                      </td><td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                        <h4 style={{}}>${transaction.amount}</h4>
                      </td></>
                  )}
                  {transaction.item_type === 'redeem_points' && (
                    <>
                      <td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                        <h4 style={{}}>{transaction.redeem_item}</h4>
                      </td>
                      <td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                        <h4 style={{ display: 'flex' }}>{transaction.redeem_instructions}</h4>
                      </td><td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                        <h4 style={{ display: 'flex' }}>
                          <img
                            loading="lazy"
                            src={pointsIcon}
                            style={{
                              width: '20px',
                              height: 'auto',
                              marginRight: '1px'
                            }} />
                          {transaction.redeem_points}
                        </h4>
                      </td></>
                  )}

                  <td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                    <h4 style={{ display: 'inline-grid', alignItems: 'end' }}>
                      {transaction.status === 'Pending' && (
                        <Chip size="xs" color="chip" radius="rounded" className="w-max">
                          <Trans message="Pending" />
                        </Chip>
                      )}
                      {transaction.status === 'Completed' && (
                        <Chip size="xs" color="positive" radius="rounded" className="w-max">
                          <Trans message="Completed" />
                        </Chip>
                      )}
                      {transaction.status === 'Rejected' && (
                        <Chip size="xs" color="danger" radius="rounded" className="w-max">
                          <Trans message="Rejected" />
                        </Chip>
                      )}
                    </h4>
                  </td>
                  <td style={{ textAlign: 'left', border: '1px solid rgb(var(--be-foreground-base) / var(--be-divider-opacity))', padding: '8px' }}>
                    <h4>{formattedDate}</h4>
                  </td>
                </tr>
              );

            })}

          </tbody>
        </table>
      </div>
    </div>
  );
}