import {useQuery} from '@tanstack/react-query';
import {PaginatedBackendResponse} from '../../../../http/backend-response/pagination-response';
import {DatabaseBalance} from '../database-balance';
import {apiClient} from '../../../../http/query-client';

const Endpoint = 'billing/support-balance-withdraw';

export interface ItemTransactionsW {
  id: number;
  status: string;
  item_type: string;
  artist_id?: number;
  amount?: number;
  payment_method?: string;
  payment_details?: string;
  redeem_instructions?: string;
  user_id?: number;
  redeem_item?: string;
  redeem_points?: number;
  created_at: string;
}

export interface FetchUserNotificationsResponse
  extends PaginatedBackendResponse<ItemTransactionsW> {
  //
}

interface Payload {
  artistId: number;
}

export function useSupportedBalanceWithdraw(payload?: Payload) {
  return useQuery(useSupportedBalanceWithdraw.key, () =>
    fetchUserNotifications(payload)
  );
}

function fetchUserNotifications(
  payload?: Payload
): Promise<FetchUserNotificationsResponse> {
  return apiClient
    .get(Endpoint, {params: payload})
    .then(response => response.data);
}

useSupportedBalanceWithdraw.key = [Endpoint];
