import {useMutation} from '@tanstack/react-query';
import {apiClient, queryClient} from '../../../../http/query-client';
import {useTrans} from '../../../../i18n/use-trans';
import {BackendResponse} from '../../../../http/backend-response/backend-response';
import {toast} from '../../../../ui/toast/toast';
import {message} from '../../../../i18n/message';
import {DatatableDataQueryKey} from '../../../../datatable/requests/paginated-resources';
import {onFormQueryError} from '../../../../errors/on-form-query-error';
import {UseFormReturn} from 'react-hook-form';
// import {Subscription} from '../../../billing/subscription';
import { SupportPayments } from '@common/billing/support-payments';
import { usePrimaryArtistForCurrentUser } from '@app/web-player/backstage/use-primary-artist-for-current-user';

interface Response extends BackendResponse {
  support_payment: SupportPayments;
}

export interface WithdrawSupportWithdrawsPayload extends Partial<SupportPayments> {
  artist_id: string;
  amount: string;
  payment_method: string;
  payment_details: string;
}

export function useWithdrawSupportWithdraws(
  form: UseFormReturn<WithdrawSupportWithdrawsPayload>
) {
  const primaryArtist = usePrimaryArtistForCurrentUser();
  const artistId = primaryArtist?.id as number;
  const {trans} = useTrans();
  return useMutation(
    (props: WithdrawSupportWithdrawsPayload) => withdrawSupportWithdraws(artistId, props),
    {
      onSuccess: () => {
        toast(trans(message('Successfully withdrawn')));
        // queryClient.invalidateQueries(
        //   DatatableDataQueryKey('billing/support-withdrawn')
        // );
      },
      onError: err => {
        onFormQueryError(err, form);

        // Extract and display the error message from the server response, or use a default message
        const errorMessage =
          trans(message('Error withdrawing. Please try again.'));
        toast(errorMessage, { type: 'danger'});
      },
    }
  );
}

function withdrawSupportWithdraws(artistId: number, {
  ...payload
}: WithdrawSupportWithdrawsPayload): Promise<Response> {
  
  console.log('artistIdSN', artistId);
  return apiClient
    .post(`billing/support-withdraws-new/${artistId}`, payload)
    .then(r => r.data);
}
