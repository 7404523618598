import {useQuery} from '@tanstack/react-query';
import {PaginatedBackendResponse} from '../../../../http/backend-response/pagination-response';
import {DatabaseBalance} from '../database-balance';
import {apiClient} from '../../../../http/query-client';

const Endpoint = 'billing/support-balance';
const ReleaseEndPoint = 'billing/distro-balance';

export interface FetchUserNotificationsResponse
  extends PaginatedBackendResponse<DatabaseBalance> {
  //
}

interface Payload {
  artistId: number;
}


export function useSupportedBalance(payload?: Payload) {
  return useQuery(useSupportedBalance.key, () =>
    fetchUserNotifications(payload)
  );
}

function fetchUserNotifications(
  payload?: Payload
): Promise<FetchUserNotificationsResponse> {
  return apiClient
    .get(Endpoint, {params: payload})
    .then(response => response.data);
}

interface ReleasePayload {
  artistId: number;
}

export function useReleasedBalance(payload?: ReleasePayload) {
  return useQuery([ReleaseEndPoint], () => fetchUserReleasedBalance(payload))
}

function fetchUserReleasedBalance(payload?: ReleasePayload): Promise<{balance: number}> {
  return apiClient
    .get(ReleaseEndPoint, {params: payload})
    .then(response => response.data)
}

useSupportedBalance.key = [Endpoint];
