import {Dialog} from '../../../ui/overlays/dialog/dialog';
import {DialogHeader} from '../../../ui/overlays/dialog/dialog-header';
import {Trans} from '../../../i18n/trans';
import {DialogBody} from '../../../ui/overlays/dialog/dialog-body';
import {DialogFooter} from '../../../ui/overlays/dialog/dialog-footer';
import {Button} from '../../../ui/buttons/button';
import {useDialogContext} from '../../../ui/overlays/dialog/dialog-context';
import {useForm} from 'react-hook-form';
import {Subscription} from '../../../billing/subscription';
// import {UpdateSupportWithdrawsPayload, useUpdateSupportWithdraws} from './requests/use-update-supportwithdraws';
// import {CrupdateSupportWithdrawsForm} from './crupdate-supportwithdraws-form';
import { SupportPayments } from '@common/billing/support-payments';
import { CrupdateSupportWithdrawsForm } from '@common/admin/support/crupdate-supportwithdraws-form';
import { CrupdateBalanceWithdrawsForm } from './crupdate-balancewithdraws-form';
import { UpdateSupportWithdrawsPayload } from '@common/admin/support/requests/use-update-supportwithdraws';
import { WithdrawSupportWithdrawsPayload, useWithdrawSupportWithdraws } from './requests/use-update-balancewithdraws';
import { Link, redirect } from 'react-router-dom';

interface BalanceSupportWithdrawsDialogProps {
  artistId: number;
}

export const removeQueryParam = (paramName: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(paramName);
  const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
  window.history.replaceState(null, '', newUrl);
};

export function BalanceSupportWithdrawsDialog({
  artistId,
}: BalanceSupportWithdrawsDialogProps) {
  const {close, formId} = useDialogContext();
  const form = useForm<WithdrawSupportWithdrawsPayload>();
  const withdrawSupportWithdraws = useWithdrawSupportWithdraws(form);
  close();

  return (
    <Dialog size="md" className='withdrawdialog'>
      <DialogHeader hideDismissButton={true}>
        <Trans message="Withdraw" />
      </DialogHeader>
      <DialogBody>
        <CrupdateBalanceWithdrawsForm
          formId={formId}
          form={form as any}
          artistId={artistId}
          onSubmit={values => {
            withdrawSupportWithdraws.mutate((values as WithdrawSupportWithdrawsPayload), {
              onSuccess: () => {
                window.location.href = window.location.pathname;
              },
            });
          }}
        />
      </DialogBody>
      <DialogFooter>

      <Link to="#">
        <Button
          // onClick={() => {
          //   window.location.href = window.location.pathname;
          // }}
        >
          <Trans message="Cancel" />
        </Button>
        </Link>

        <Button
          form={formId}
          // disabled={updateSubscription.isLoading}
          variant="flat"
          color="primary"
          type="submit"
        >
          <Trans message="Submit" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
