import {useQuery} from '@tanstack/react-query';
import {PaginatedBackendResponse} from '../../../../http/backend-response/pagination-response';
import {DatabaseBalance} from '../database-balance';
import {apiClient} from '../../../../http/query-client';

const Endpoint = 'billing/points-balance';

export interface FetchUserNotificationsResponse
  extends PaginatedBackendResponse<any> {
  //
}

interface Payload {
  userId: number;
}

export function usePointsBalance(payload?: Payload) {
  return useQuery(usePointsBalance.key, () =>
    fetchUserNotifications(payload)
  );
}

function fetchUserNotifications(
  payload?: Payload
): Promise<FetchUserNotificationsResponse> {
  return apiClient
    .get(Endpoint, {params: payload})
    .then(response => response.data);
}

usePointsBalance.key = [Endpoint];
