import {UseFormReturn} from 'react-hook-form';
import {Form} from '../../../ui/forms/form';
import {FormTextField} from '../../../ui/forms/input-field/text-field/text-field';
import {FormSelect} from '../../../ui/forms/select/select';
import {Trans} from '../../../i18n/trans';
import {Item} from '../../../ui/forms/listbox/item';
// import {Subscription} from '../../billing/subscription';
import {FormDatePicker} from '../../../ui/forms/input-field/date/date-picker/date-picker';
import {USER_MODEL} from '../../../auth/user';
import {useProducts} from '../../../billing/pricing-table/use-products';
import {FormattedPrice} from '../../../i18n/formatted-price';
// import {FormNormalizedModelField} from '../../../ui/forms/normalized-model-field';
import { SupportPayments } from '@common/billing/support-payments';

interface CrupdateBalanceWithdrawsForm {
  onSubmit: (values: Partial<SupportPayments>) => void;
  formId: string;
  form: UseFormReturn<Partial<SupportPayments>>;
  artistId: number;
}

export function CrupdateBalanceWithdrawsForm({
  form,
  onSubmit,
  formId,
  artistId
}: CrupdateBalanceWithdrawsForm) {
  // const query = useProducts();
  // // @ts-ignore
  // const watchedProductId = form.watch('product_id');
  // const selectedProduct = query.data?.products.find(
  //   p => p.id === watchedProductId
  // );

  console.log('artistId', artistId);

  return (
    <Form id={formId} form={form} onSubmit={onSubmit}>

      <input type="hidden" value={artistId} name="artist_id" />

      <FormSelect
        name="amount"
        selectionMode="single"
        className="mb-20"
        label={<Trans message="Amount" />}
        required
      >
        <Item key='10' value='10'>
          <Trans message='$10' />
        </Item>

        <Item key='20' value='20'>
          <Trans message='$20' />
        </Item>
        
        <Item key='30' value='30'>
          <Trans message='$30' />
        </Item>
        
        <Item key='40' value='40'>
          <Trans message='$40' />
        </Item>
        
        <Item key='50' value='50'>
          <Trans message='$50' />
        </Item>
        
        <Item key='60' value='60'>
          <Trans message='$60' />
        </Item>
        
        <Item key='70' value='70'>
          <Trans message='$70' />
        </Item>
        
        <Item key='80' value='80'>
          <Trans message='$80' />
        </Item>
        
        <Item key='90' value='90'>
          <Trans message='$90' />
        </Item>
        
        <Item key='100' value='100'>
          <Trans message='$100' />
        </Item>
        
        <Item key='200' value='200'>
          <Trans message='$200' />
        </Item>

        <Item key='300' value='300'>
          <Trans message='$300' />
        </Item>

        <Item key='400' value='400'>
          <Trans message='$400' />
        </Item>

        <Item key='500' value='500'>
          <Trans message='$500' />
        </Item>
        
        <Item key='600' value='600'>
          <Trans message='$600' />
        </Item>
        
        <Item key='700' value='700'>
          <Trans message='$700' />
        </Item>
        
        <Item key='800' value='800'>
          <Trans message='$800' />
        </Item>
        
        <Item key='900' value='900'>
          <Trans message='$900' />
        </Item>
        
        <Item key='1000' value='1000'>
          <Trans message='$1000' />
        </Item>
        
      </FormSelect>
      
      <FormSelect
        name="payment_method"
        selectionMode="single"
        className="mb-20"
        label={<Trans message="Payment method" />}
        required={true}
      >
        <Item key='Paypal' value='Paypal'>
          <Trans message='Paypal' />
        </Item>
        
        <Item key='Bank Account' value='Bank Account'>
          <Trans message='Bank Account' />
        </Item>
        
      </FormSelect>

      <FormTextField
        className="mb-30 flex-auto"
        name='payment_details'
        inputElementType="textarea"
        rows={8}
        required={true}
        label={<Trans message="Payment Information" />}
      />

    </Form>
  );
}
